<template>
  <div class='back'>
    <el-button type="primary" icon="el-icon-arrow-left" plain @click="$router.go(-1)" style="margin-bottom: 20px;">返回</el-button>
  </div>
</template>

<script>

export default {
  data () {
    return {

    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
.back {}
</style>
